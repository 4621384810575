import React from 'react'
import { Table, Select } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { updateMonitoringScheduleCurriculum } from '../../../services/monitoring'
import { notificationSuccess, notificationError } from '../../ui/Notifications'

const defaultTypeCurriculum = {
  module: 'Modul',
  url: 'URL',
  video: 'Rekaman Video',
  task: 'Tugas'
}

const defaultStatus = [
    {
      label: 'Terbuka',
      value: 'open'
    },
    {
      label: 'Ditutup',
      value: 'closed'
    }
  ]

const CurriculumScheduleList = ({ label, data, lockStatus, handleReload }) => {
  const parameter = useParams()
  const scheduleId = parameter.id

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.curriculum.name}</Table.Td>
        <Table.Td>{defaultTypeCurriculum[val.curriculum.type]}</Table.Td>
        <Table.Td>{handleMappingStatus(val)}</Table.Td>
      </Table.Tr>
    )
  })

  const handleMappingStatus = (value) => {

    const handleChangeStatus = async (curriculumid, val) => {
      const payload = {
        status: val
      }
      try {
        const response = await updateMonitoringScheduleCurriculum(scheduleId, curriculumid, payload)
        if (response) {
          notificationSuccess('Perubahan Status Materi Berhasil', 'Anda telah merubah status materi')
          handleReload()
        }
      } catch (error) {
        const errorMessage = error.response.data.message
        notificationError('Perubahan Status Materi Gagal', errorMessage)
      }
    }

    return (
      <Select
        size='xs'
        data={defaultStatus}
        value={value.status}
        onChange={(val) => handleChangeStatus(value.id, val)}
        disabled={!lockStatus}
    />
    )
  }
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default CurriculumScheduleList