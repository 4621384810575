import React, { useState, useEffect } from 'react'
import { Box, Text, Flex, Pagination, TextInput, Select } from '@mantine/core'
import AuthLayout from '../../../layout/AuthLayout'
import { getListMonitoringSchedule } from '../../../services/monitoring'
import classes from '../AdminLayout.module.css'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TableMonitoring from '../../../components/pages/Monitoring/TableMonitoring'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'
import { useNavigate } from 'react-router-dom'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc',
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'fullName',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Jadwal',
    width: 'auto'
  },
  {
    label: 'Pelatihan',
    width: 'auto'
  },
  {
    label: 'Akses',
    width: 'auto'
  },
  {
    label: 'Tipe',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const Monitoring = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [params, setParams] = useState(defaultParameter)
  const [monitoringScheduleList, setMonitoringScheduleList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Jadwal Pelatihan',
      route: null
    }
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetMonitoringSchedule = async () => {
    setLoading(true)
    try {
      const response = await getListMonitoringSchedule(params)
      setMonitoringScheduleList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetMonitoringSchedule()
    // eslint-disable-next-line
  }, [params])

  const actions = {
    participant: (val) => {
      navigate(`/monitoring/${val.id}/participant`)
    },
    curriculum: (val) => {
      navigate(`/monitoring/${val.id}/curriculum`)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Pemantauan Jadwal Pelatihan</Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Jadwal. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={6} /> : <TableMonitoring label={labelTable} data={monitoringScheduleList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default Monitoring