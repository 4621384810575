import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getListMonitoringScheduleParticipant } from '../../../../../services/monitoring'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableMonitoringParticipant from '../../../../../components/pages/Monitoring/TableMonitoringParticipant'
import { updateRoute } from '../../../../../store/dataRoute'
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'


const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc',
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'user-fullName',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Email',
    width: 'auto'
  },
  {
    label: 'Jabatan',
    width: 'auto'
  },
  {
    label: 'Kemajuan Pelatihan',
    width: 'auto'
  },
  {
    label: 'Status',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const ScheduleParticipant = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const parameter = useParams()
  const scheduleId = parameter.id
  const [params, setParams] = useState(defaultParameter)
  const [participantList, setParticipantList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState(null)

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Jadwal Pelatihan',
      route: '/monitoring'
    },
    {
      label: 'Peserta',
      route: null
    },
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetMonitoringScheduleParticipant = async () => {
    setLoading(true)
    try {
      const response = await getListMonitoringScheduleParticipant(scheduleId, params)
      setParticipantList(response.data)
      setDetail(response.data[0].schedule)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetMonitoringScheduleParticipant()
    // eslint-disable-next-line
  }, [params])

  const actions = {
    detail: (val) => {
      navigate(`/monitoring/${scheduleId}/participant/${val.id}/attempt`)
    },
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={4}>Manajemen Peserta Jadwal Pelatihan {detail !== null ? detail.name : ''}</Text>
        <Text fz={12} mb={20}>Pelatihan: <Text fz={12} component='span' fw='bold'>{detail !== null ? detail.event.name : ''}</Text></Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Peserta. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={6} /> : <TableMonitoringParticipant label={labelTable} data={participantList} actionMethod={handleAction} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default ScheduleParticipant