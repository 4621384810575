import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select, Button, Modal, Center, Loader, Badge } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getDetailSchedule } from '../../../../../services/event-schedule'
import { getListUserSchedule, deleteUserSchedule, generateUserSchedule } from '../../../../../services/event-user-schedule'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableEventUserSchedule from '../../../../../components/pages/EventUserSchedule/TableEventUserSchedule'
import FormEventUserSchedule from '../../../../../components/pages/EventUserSchedule/FormEventUserSchedule'
import { modalDeleteData } from '../../../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { updateRoute } from '../../../../../store/dataRoute'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'user-fullName',
    label: 'Nama'
  },
]

const defaultParticipant = [
  {
    label: 'Peserta',
    value: 0
  },
  {
    label: 'Pembimbing',
    value: 1
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Email',
    width: 'auto'
  },
  {
    label: 'Jabatan',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const UserScheduleEvent = () => {
  const params = useParams()
  const eventId = params.id
  const scheduleId = params.schedule_id
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    schedule: scheduleId,
    orderBy: 'createdAt',
    order: 'desc',
    isTutor: 0
  }
  const permissionState = useSelector(state => state.permission.access)
  const [parameter, setParameter] = useState(defaultParameter)
  const [detailScheduleEvent, setDetailScheduleEvent] = useState(null)
  const [userScheduleList, setUserScheduleList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan',
      route: '/event'
    },
    {
      label: 'Jadwal',
      route: `/event/${eventId}/schedule`
    },
    {
      label: 'Peserta',
      route: null
    },
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: parameter.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: parameter.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetEventUserScheduleList = async () => {
    setLoadingTable(true)
    if (detailScheduleEvent === null) {
      setLoading(true)
    }
    try {
      const response = await getListUserSchedule(parameter)
      setUserScheduleList(response.data)
      setCount(response.count)
      if (detailScheduleEvent === null) {
        handleGetDetailScheduleEvent(scheduleId)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleGetDetailScheduleEvent = async (idSchedule) => {
    try {
      const response = await getDetailSchedule(idSchedule)
      if (response) {
        setDetailScheduleEvent(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGenerateParticipant = async (idSchedule) => {
    const payload = {
      scheduleId: idSchedule
    }
    try {
      await generateUserSchedule(payload)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetEventUserScheduleList()
    if (parameter.isTutor === 0) {
      handleGenerateParticipant(scheduleId)
    }
    // eslint-disable-next-line
  }, [parameter])

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParameter((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData(`${parameter.isTutor === 0 ? 'Peserta' : 'Pembimbing'}`, val.id, val.user.fullName, deleteUserSchedule, handleGetEventUserScheduleList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionSchedule = permissionState['schedule']
    const createdPermission = permissionSchedule.find(val => val.alias === 'schedule.create')
    if (createdPermission !== undefined && detailScheduleEvent.event.scope) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah {parameter.isTutor === 0 ? 'Peserta' : 'Pembimbing'}</Button>
      )
    }
  }
  
  const loadData = () => {

    const mappingParticipant = (type) => type.map((val, index) => {
      return (
        <Badge size='md' key={index} variant={parameter.isTutor === val.value ? 'filled' : 'outline'} onClick={() => handleFilter('isTutor', val.value)} style={{ cursor: 'pointer' }}>{val.label}</Badge>
      )
    })

    return (
      <Box>
        <Text className={classes.titleLayout} mb={4}>Manajemen Peserta Jadwal "{detailScheduleEvent.name}"</Text>
        <Text fz={14} mb={20}>Pelatihan "{detailScheduleEvent.event.name}"</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex mb={16} direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Peserta. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={parameter.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={parameter.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
          <Flex gap='xs'>
            {mappingParticipant(defaultParticipant)}
          </Flex>
        </Box>
        <Box my={20}>
          {loadingTable ? <TableSkeleton total={5} /> : <TableEventUserSchedule label={labelTable} data={userScheduleList} actionMethod={handleAction} /> }
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / parameter.take) || 0} />
        </Flex>
      </Box>
    )
  }

  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData /> : loadData()}
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} {parameter.isTutor === 0 ? 'Peserta' : 'Pembimbing'}</Text>} withCloseButton={false}>
        <FormEventUserSchedule onCloseModal={onCloseModalForm} reloadList={handleGetEventUserScheduleList} typeParticipant={parameter.isTutor === 0 ? 'user' : 'tutor'} />
      </Modal>
    </AuthLayout>
  )
}

export default UserScheduleEvent