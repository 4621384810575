import React from 'react'
import { Table, Menu, ActionIcon, Badge } from '@mantine/core'
import { IconDotsVertical, IconUsersGroup, IconListDetails } from '@tabler/icons-react'

const ActionMenu = ({ handleAction }) => {

  return (
    <Menu width={160} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={8} pt={6} variant='transparent' size='xs'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconUsersGroup stroke={1.5} size={14} />}
          onClick={() => handleAction('participant')}
          fw='600'
          fz={11}
        >
          Peserta Pelatihan
        </Menu.Item>
        <Menu.Item
          leftSection={<IconListDetails stroke={1.5} size={14} />}
          onClick={() => handleAction('curriculum')}
          fw='600'
          fz={11}
        >
          Materi Pelatihan
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TableMonitoring = ({ label, data, actionMethod }) => {

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {

    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.name}</Table.Td>
        <Table.Td>{val.event.name}</Table.Td>
        <Table.Td tt='capitalize'>{val.event.type}</Table.Td>
        <Table.Td>{val.event.isRequired ? 'Wajib' : 'Opsional'}</Table.Td>
        <Table.Td><Badge size="xs" radius="sm" variant="filled" color={val.event.isActive ? 'green' : 'red'}>{val.event.isActive ? 'Aktif' : 'Tidak Aktif'}</Badge></Table.Td>
        <Table.Td>{<ActionMenu handleAction={(type) => actionMethod(val, type)} />}</Table.Td>
      </Table.Tr>
    )
  })
  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableMonitoring