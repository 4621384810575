import React, { useState, useEffect } from 'react'
import { Box, Text, List, Flex, Button, ScrollArea, Group, Checkbox, CheckIcon, Paper } from '@mantine/core'
import { getAttemptTask, getTaskQuestion, createStartTaskQuestion, createTaskAnswer, submitTaskAnswer } from '../../../../services/member-event-attempt'
import { useParams } from 'react-router-dom'
import classes from '../../../../pages/member/MemberLayout.module.css'
import LoadingData from '../../../ui/LoadingData'
import { notificationError } from '../../../ui/Notifications'
import { useViewportSize } from '@mantine/hooks'
import swallAlert from '../../../../plugins/sweet-alert'

const defaultParameter = {
  skip: 0,
  take: 1,
}


const TaskEvent = ({ detail, dataTask }) => {
  const { height } = useViewportSize()
  const params = useParams()
  const scheduleId = params.schedule_id
  const [answerAttemptId, setAnswerAttemptId] = useState(null)
  const [detailCurriculum, setDetailCurriculum] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingExam, setLoadingExam] = useState(false)
  const [loadingNextTask, setLoadingNextTask] = useState(false)
  const [startExam, setStartExam] = useState(false)
  const [taskQuestions, setTaskQuestion] = useState([])
  const [taskCount, setTaskCount] = useState(0)
  const [questParams, setQuestParams] = useState(defaultParameter)
  const [selectedAnswer, setSelectedAnswer] = useState(null)

  const handleGetDetailTask = async (curriculumTaskId) => {
    setLoading(true)
    try {
      const response = await getAttemptTask(scheduleId, curriculumTaskId)
      setDetailCurriculum(response.data)
      setAnswerAttemptId(null)
      setStartExam(false)
      setTaskCount(0)
      setQuestParams(defaultParameter)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleStartExam = async (taskId) => {
    setLoadingExam(true)
    const payload = {
      userScheduleId: scheduleId,
      curriculumTaskId: taskId
    }
    try {
      const response = await createStartTaskQuestion(payload)
      if (response) {
        setAnswerAttemptId(response.id)
        await handleQuestionExam(response.id)
        setStartExam(true)
      }
    } catch (error) {
      console.log(error)
      notificationError('Tidak Bisa Memulai Quiz', `${error.response.data.message === 'maximum attempt reached' ? 'Anda sudah melebihi batas pengerjaan materi ini' : error.response.data.message}`)
    } finally {
      setLoadingExam(false)
    }
  }

  const handleQuestionExam = async (attemptId) => {
    setLoading(true)
    try {
      const response = await getTaskQuestion(attemptId, questParams)
      setTaskQuestion(response.data)
      setTaskCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmitTaskAnswer = async () => {
    setLoadingNextTask(true)
    const payload = {
      taskAnswerId: selectedAnswer
    }
    try {
      const response = await createTaskAnswer(answerAttemptId, payload)
      return response
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingNextTask(false)
    }
  }

  const generateSubmitTask = async () => {
    setLoadingNextTask(true)
    try {
      const response = await submitTaskAnswer(answerAttemptId)
      if (response) {
        swallAlert.fire({
          icon: `success`,
          title: `Selamat`,
          text: `Anda Telah Menyelesaikan Materi ini`
        })
        setAnswerAttemptId(null)
        setStartExam(false)
        setTaskCount(0)
        setQuestParams(defaultParameter)
        handleGetDetailTask(detail.id)
        setLoadingNextTask(false)
        setSelectedAnswer(null)
      }
    } catch (error) {
      console.log(error)
      const errorMessage = error.response.data.message
      notificationError('Gagal Submit Materi', `${errorMessage}`)
      setLoadingNextTask(false)
    }
  }

  const handlePrevPage = () => {
    // eslint-disable-next-line
    setQuestParams((oldVal) => ({ ...oldVal, ['skip']: (questParams.skip - 1) }))
  }

  const handleNextPage = () => {
    // console.log(questParams.skip + 1)
    // eslint-disable-next-line
    setQuestParams((oldVal) => ({ ...oldVal, ['skip']: (questParams.skip + 1) }))
  }

  useEffect(() => {
    if (detail !== null) {
      handleGetDetailTask(detail.id)
    }
    // eslint-disable-next-line
  }, [detail])

  useEffect(() => {
    if (answerAttemptId !== null && startExam) {
      handleQuestionExam(answerAttemptId)
    }
    // eslint-disable-next-line
  }, [questParams])

  const initialTask = (detail) => {
    if (detail !== null) {
      return (
        <Box pos='relative'>
          <Text className={classes.titleLayout} mb={6}>{detail.task.name}</Text>
          <Text fz={12} color="dimmed">Kode Soal: <Text fz={12} component="span" fw="bold">{detail.task.code}</Text></Text>
          <Box my={12}>
            <Text fz={12} mb={16}>{detail.task.description}</Text>
            <Box ml={10}>
              <List fz={13}>
                <List.Item>Tipe: <Text fz={13} component='span' fw='bold'>{detail.task.isChoice ? 'Pilihan Ganda' : 'Isian'}</Text></List.Item>
                <List.Item>Nilai Kelulusan: <Text fz={13} component='span' fw='bold'>{detail.task.passingScore !== null ? `${detail.task.passingScore} / 100` : 'Tidak ada batasan minimum'}</Text></List.Item>
                <List.Item>Maksimal Mengulang Pengerjaan: <Text fz={13} component='span' fw='bold'>{detail.task.maxAttempt !== null ? detail.task.maxAttempt : 'Tidak terbatas'}</Text></List.Item>
              </List>
            </Box>
          </Box>
          <Box mt={30}>
            <Flex justify="space-between" align="center">
              <Text fz={13}>Nilai Anda : <Text fz={13} component='span' fw="bold">{detailCurriculum.length > 0 ? detailCurriculum[0].score !== null ? detailCurriculum[0].score : '-' : '-'}</Text></Text>
              <Button size='xs' loading={loadingExam} onClick={() => handleStartExam(detail.id)}>Mulai Quiz</Button>
            </Flex>
          </Box>
        </Box>
      )
    }
  }

  const questionTask = (taskQuest) => {
    if (taskQuest.length > 0) {
      const questTask = taskQuest[0]
      const innerHtml = { __html: questTask.text }

      const handleSubmit = async () => {
        if (questTask.order < taskCount) {
          const submitAnswer = await handleSubmitTaskAnswer()
          if (submitAnswer) {
            setSelectedAnswer(null)
            handleNextPage()
          }
        } else {
          await generateSubmitTask()
        }
      }

      const mappingAnswer = (answer) => {
        const remapAnswer = answer.map((val, index) => {
          return (
            <Group gap='sm' key={index} wrap="nowrap" mb={12}>
              <Checkbox radius='xl' size='xs' color='green' icon={CheckIcon} checked={val.id === selectedAnswer}  onChange={() => setSelectedAnswer(val.id)} />
              <Text fz={13}>{val.text}</Text>
            </Group>
          )
        })
        return remapAnswer
      }

      return (
        <Box mih={600} pos='relative'>
          <Box mb="xl">
            <Box>
              <Paper shadow="xs" withBorder p="md" radius='md'>
                <ScrollArea h={500} offsetScrollbars scrollbarSize={6}>
                  <Box component='div' style={{ width: '100%' }} className="ck-content" dangerouslySetInnerHTML={innerHtml} />
                </ScrollArea>
              </Paper>
              <Box my={20}>
                {mappingAnswer(questTask.taskAnswers)}
              </Box>
              <Box mt={30}>
                <Flex justify="space-between" align="center" >
                  <Button size='xs' onClick={() => handlePrevPage()} disabled={questTask.order === 1}>Sebelumnya</Button>
                  <Button size='xs' loading={loadingNextTask} onClick={() => handleSubmit()} disabled={selectedAnswer === null}>{questTask.order === taskCount ? 'Selesaikan' : 'Selanjutnya'}</Button>
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } 
  }
  
  return (
    <Box mih={height / 1.25}>
      {loading ? <LoadingData /> : !startExam ? initialTask(dataTask) : questionTask(taskQuestions)}
    </Box>
  )
}

export default TaskEvent