import http from '../plugins/axios'

export const getListMemberEvent = async (params) => {
  try {
    const response = await http.get(`/in/events`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailMemberEvent = async (eventId) => {
  try {
    const response = await http.get(`/in/events/${eventId}`)
    return response.data
  } catch(error) {
    throw error
  }
}