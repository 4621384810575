import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getListMonitoringAttempt } from '../../../../../services/monitoring'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableParticipantAttempt from '../../../../../components/pages/Monitoring/TableParticipantAttempt'
import { updateRoute } from '../../../../../store/dataRoute'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Soal',
    width: 'auto'
  },
  {
    label: 'Hasil Akhir',
    width: 'auto'
  },
  {
    label: 'Tanggal Penyelesaian Pengerjaan',
    width: 'auto'
  },
]

const ParticipantAttempt = () => {
  const dispatch = useDispatch()
  const parameter = useParams()
  const scheduleId = parameter.id
  const participantId = parameter.participant_id
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    userSchedule: participantId,
    orderBy: 'createdAt',
    order: 'desc'
  }
  const [params, setParams] = useState(defaultParameter)
  const [attemptList, setAttemptList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState(null)

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Jadwal Pelatihan',
      route: '/monitoring'
    },
    {
      label: 'Peserta',
      route: `/monitoring/${scheduleId}/participant`
    },
    {
      label: 'Materi',
      route: null
    },
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetMonitoringParticipantAttempt = async () => {
    setLoading(true)
    try {
      const response = await getListMonitoringAttempt(params)
      setAttemptList(response.data)
      setDetail(response.data[0].userSchedule.user)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetMonitoringParticipantAttempt()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }
  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={4}>Manajemen Jawaban Peserta </Text>
        <Text fz={12} mb={20}>Nama: <Text fz={12} component='span' fw='bold'>{detail !== null ? detail.fullName : ''}</Text></Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Materi. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={4} /> : <TableParticipantAttempt label={labelTable} data={attemptList} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default ParticipantAttempt