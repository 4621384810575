import React, { useState, useEffect } from 'react'
import { Box, Center, Loader, Flex, Group, Button, Paper, Text } from '@mantine/core'
import { getDetailMemberEvent } from '../../../../services/member-event'
import { createNewEventSchedule } from '../../../../services/member-event-schedule'
import { useViewportSize } from '@mantine/hooks'
import { notificationSuccess, notificationError } from '../../../ui/Notifications'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 2.2}>
      <Loader size='sm' type='bars' />
    </Center>
  )
}

const EventScheduleList = ({ detail, onCloseModal }) => {
  const { height } = useViewportSize()
  const [loadingData, setLoadingData] = useState(true)
  const [scheduleList, setScheduleList] = useState([])

  const handleGetDetailEvent = async (eventId) => {
    setLoadingData(true)
    try {
      const response = await getDetailMemberEvent(eventId)
      const dataSchedule = response.schedules
      setScheduleList(dataSchedule)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingData(false)
    }
  }

  useEffect(() => {
    if (detail !== null) {
      handleGetDetailEvent(detail.id)
    }
  // eslint-disable-next-line
  }, [detail])

  const handleCreateNewSchedule = async (val) => {
    const payload = {
      scheduleId: val.id
    }
    try {
      const response = await createNewEventSchedule(payload)
      if (response) {
        onCloseModal()
        notificationSuccess('Ambil Jadwal Berhasil', 'anda telah mengambil jadwal baru')
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      notificationError('Ambil Jadwal Gagal', `${errorMessage}`)
    }
  }

  const loadDataSchedule = (data) => {
    if (data.length > 0) {
      const remapList = data.map((val, index) => {
        return (
          <Paper shadow="sm" radius="md" withBorder mb='md' p='md' key={index}>
            <Group justify="space-between" gap="xs">
              <Text size='sm' fw='600' lineClamp={1}>{index + 1}. {val.name}</Text>
              <Button size='xs' disabled={val.isJoined} onClick={!val.isJoined ? () => handleCreateNewSchedule(val) : ''}>{val.isJoined ? 'Sudah Diambil' : 'Ambil Pelatihan'}</Button>
            </Group>
          </Paper>
        )
      })
      return remapList
    } else {
      return (
        <Center h={height / 2.2}>
          <Text size='sm' c='gray.5'>Tidak Ada Jadwal Tersedia</Text>
        </Center>
      )
    }
  }
  return (
    <Box>
      {loadingData ? <LoadingData /> : loadDataSchedule(scheduleList)}
      <Box mt={30}>
        <Flex justify='flex-end'>
          <Group>
            <Button size='xs' variant="outline" onClick={onCloseModal}>Tutup</Button>
          </Group>
        </Flex>
      </Box>
    </Box>
  )
}

export default EventScheduleList