import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select, Button, Modal, Center, Loader } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getDetailSchedule } from '../../../../../services/event-schedule'
import { getListRecording, deleteRecording } from '../../../../../services/event-recording'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import TableEventRecording from '../../../../../components/pages/EventRecording/TableEventRecording'
import FormEventRecording from '../../../../../components/pages/EventRecording/FormEventRecording'
import { modalDeleteData } from '../../../../../components/ui/Modals/modalDeleteData'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useViewportSize } from '@mantine/hooks'
import { updateRoute } from '../../../../../store/dataRoute'

const LoadingData = () => {
  const { height } = useViewportSize()
  return (
    <Center h={height / 1.2}>
      <Loader size='md' type='bars' />
    </Center>
  )
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 'auto'
  },
  {
    label: 'Action',
    width: 100
  },
]

const RecordingEvent = () => {
  const params = useParams()
  const eventId = params.id
  const scheduleId = params.schedule_id
  const defaultParameter = {
    skip: 0,
    take: 10,
    search: '',
    schedule: scheduleId,
    orderBy: 'createdAt',
    order: 'desc'
  }

  const permissionState = useSelector(state => state.permission.access)
  const [parameter, setParameter] = useState(defaultParameter)
  const [detailSchedule, setDetailSchedule] = useState(null)
  const [recordingList, setRecordingList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingTable, setLoadingTable] = useState(true)
  const [openModalForm, setOpenModalForm] = useState(false)
  const [detailData, setDetailData] = useState(null)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Pelatihan',
      route: '/event'
    },
    {
      label: 'Jadwal',
      route: `/event/${eventId}/schedule`
    },
    {
      label: 'Rekaman Video',
      route: null
    },
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: parameter.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: parameter.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetEventRecordingList = async () => {
    setLoadingTable(true)
    if (detailSchedule === null) {
      setLoading(true)
    }
    try {
      const response = await getListRecording(parameter)
      setRecordingList(response.data)
      setCount(response.count)
      if (detailSchedule === null) {
        handleGetDetailSchedule(scheduleId)
      } else {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingTable(false)
    }
  }

  const handleGetDetailSchedule = async (idSchedule) => {
    try {
      const response = await getDetailSchedule(idSchedule)
      if (response) {
        setDetailSchedule(response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetEventRecordingList()
    // eslint-disable-next-line
  }, [parameter])

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParameter((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const actions = {
    edit: (val) => {
      setOpenModalForm(true)
      setDetailData(val)
    },
    delete: (val) => {
      modalDeleteData('rekaman video', val.id, val.name, deleteRecording, handleGetEventRecordingList)
    }
  }

  const handleAction = (val, type) => {
    return actions[type](val)
  }

  const onCloseModalForm = () => {
    setOpenModalForm(false)
    setDetailData(null)
  }

  const handleCreate = () => {
    const permissionRecording = permissionState['recording']
    const createdPermission = permissionRecording.find(val => val.alias === 'recording.create')
    if (createdPermission !== undefined) {
      return (
        <Button size='xs' onClick={() => setOpenModalForm(true)}>Tambah Rekaman</Button>
      )
    }
  }

  const loadData = () => {
    return (
      <Box>
        <Text className={classes.titleLayout} mb={4}>Manajemen Rekaman Video Jadwal "{detailSchedule.name}"</Text>
        <Text fz={16} c='gray.6' mb={20}>Pelatihan "{detailSchedule.event.name}"</Text>
        <Box>
          <Flex justify='flex-end' mb={40}>
            {permissionState !== null ? handleCreate() : ''}
          </Flex>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Jadwal. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={parameter.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={parameter.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loadingTable ? <TableSkeleton total={4} /> : <TableEventRecording label={labelTable} data={recordingList} actionMethod={handleAction} /> }
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / parameter.take) || 0} />
        </Flex>
      </Box>
    )
  }

  return (
    <AuthLayout>
      <Box>
        {loading ? <LoadingData /> : loadData()}
      </Box>
      <Modal opened={openModalForm} onClose={onCloseModalForm} centered closeOnClickOutside={false} size='lg' title={<Text fw='Bold'>{detailData === null ? 'Tambah' : 'Update'} Jadwal Pelatihan</Text>} withCloseButton={false}>
        <FormEventRecording dataRecording={detailData} onCloseModal={onCloseModalForm} reloadList={handleGetEventRecordingList} />
      </Modal>
    </AuthLayout>
  )
}

export default RecordingEvent