import React from 'react'
import { Table } from '@mantine/core'
import dayjs from 'dayjs'

const TableParticipantAttempt = ({ label, data, actionMethod }) => {

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.curriculumTask.task.name}</Table.Td>
        <Table.Td>{val.score !== null ? val.score : 'Belum Dikerjakan'}</Table.Td>
        <Table.Td>{val.submittedAt !== null ? dayjs(val.submittedAt).format('DD MMMM YYYY') : 'Belum Ada Pengerjaan'}</Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableParticipantAttempt