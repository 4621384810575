import React from 'react'
import { Table, Menu, ActionIcon } from '@mantine/core'
import { IconDotsVertical, IconChecklist } from '@tabler/icons-react'

const ActionMenu = ({ handleAction }) => {
  return (
    <Menu width={150} position="bottom-end" withArrow arrowPosition="center">
      <Menu.Target>
        <ActionIcon ml={8} pt={6} variant='transparent' size='xs'>
          <IconDotsVertical stroke={1.5} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconChecklist stroke={1.5} size={14} />}
          onClick={() => handleAction('detail')}
          fw='600'
          fz={11}
        >
          Jawaban Peserta
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

const TableMonitoringParticipant = ({ label, data, actionMethod }) => {

  const mappingTableHead = (data) => data.map((val, index) => {
    return (<Table.Th key={index} width={val.width !== 'auto' ? val.width : ''}>{val.label}</Table.Th>)
  })

  const mappingDataTable = data => data.map((val, index) => {
    return (
      <Table.Tr key={index}>
        <Table.Td>{index + 1}.</Table.Td>
        <Table.Td>{val.user.fullName}</Table.Td>
        <Table.Td>{val.user.email}</Table.Td>
        <Table.Td>{val.user.position !== null ? val.user.position.name : '-'}</Table.Td>
        <Table.Td>{val.progress !== null ? `${val.progress * 100} %` : 'Belum Lulus'}</Table.Td>
        <Table.Td>{val.completion ? 'Lulus' : 'Belum Lulus'}</Table.Td>
        <Table.Td><ActionMenu handleAction={(type) => actionMethod(val, type)} /></Table.Td>
      </Table.Tr>
    )
  })

  return (
    <Table.ScrollContainer minWidth={768}>
      <Table highlightOnHover withTableBorder style={{ fontSize: '12px' }}>
        <Table.Thead>
          <Table.Tr>
            {mappingTableHead(label)}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {mappingDataTable(data)}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  )
}

export default TableMonitoringParticipant