import http from '../plugins/axios'

export const getListMonitoringSchedule = async (params) => {
  try {
    const response = await http.get(`/mo/schedules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getDetailMonitoringSchedule = async (scheduleId) => {
  try {
    const response = await http.get(`/mo/schedules/${scheduleId}`)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getListMonitoringScheduleParticipant = async (scheduleId, params) => {
  try {
    const response = await http.get(`/mo/schedules/${scheduleId}/participants`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const getListMonitoringScheduleCurriculum = async (scheduleId, params) => {
  try {
    const response = await http.get(`/mo/schedules/${scheduleId}/curriculum-schedules`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}
export const updateMonitoringScheduleCurriculum = async (scheduleId, curriculumId, payload) => {
  try {
    const response = await http.patch(`/mo/schedules/${scheduleId}/curriculum-schedules/${curriculumId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const getListMonitoringAttempt = async (params) => {
  try {
    const response = await http.get(`/mo/attempts`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}