import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../layout/AuthLayout'
import { Box, Text, Flex, Pagination, TextInput, Select } from '@mantine/core'
import classes from '../AdminLayout.module.css'
import { getListPosition } from '../../../services/position'
import TableSkeleton from '../../../components/ui/TableSkeleton'
import TablePosition from '../../../components/pages/Position/TablePosition'
import { useDispatch } from 'react-redux'
import { updateRoute } from '../../../store/dataRoute'

const defaultParameter = {
  skip: 0,
  take: 10,
  search: '',
  orderBy: 'createdAt',
  order: 'desc'
}

const defaultFilter = [
  {
    value: 'createdAt',
    label: 'Terbaru'
  },
  {
    value: 'name',
    label: 'Nama'
  },
]

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama',
    width: 200
  },
  {
    label: 'Deskripsi Jabatan',
    width: 'auto'
  },
]

const Position = () => {
  const [params, setParams] = useState(defaultParameter)
  const [positionList, setPositionList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Jabatan',
      route: null
    }
  ]

  const mappingOrder = [
    {
      value: 'asc',
      label: params.orderBy === 'createdAt' ? 'Terlama - Terbaru' : 'A - Z'
    },
    {
      value: 'desc',
      label: params.orderBy === 'createdAt' ? 'Terbaru - Terlama' : 'Z - A'
    },
  ]

  const handleGetPositionList = async () => {
    setLoading(true)
    try {
      const response = await getListPosition(params)
      setPositionList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetPositionList()
    // eslint-disable-next-line
  }, [params])

  const handleChangePage = (val) => {
    // eslint-disable-next-line
    setParams((oldVal) => ({ ...oldVal, ['skip']: (val - 1) * 10 }))
  }

  const handleFilter = (name, val) => {
    setParams((oldVal) => ({ ...oldVal, [name]: val}))
  }

  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={20}>Manajemen Jabatan Pengguna</Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
            <TextInput
              placeholder='Cari Posisi. . .'
              name="search"
              onChange={(val) => handleFilter('search', val.target.value)}
              size='xs'
            />
            <Select
              name="orderBy"
              data={defaultFilter}
              onChange={(e) => [handleFilter('orderBy', e)]}
              value={params.orderBy}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
            <Select
              name="order"
              data={mappingOrder}
              onChange={(e) => [handleFilter('order', e)]}
              value={params.order}
              checkIconPosition="right"
              allowDeselect={false}
              size='xs'
            />
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={3} /> : <TablePosition label={labelTable} data={positionList} />}
        </Box>
        <Flex justify='end'>
          <Pagination size='sm' onChange={handleChangePage} total={Math.ceil(count / params.take) || 0} />
        </Flex>
      </Box>
    </AuthLayout>
  )
}

export default Position