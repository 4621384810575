import React, { useState, useEffect } from 'react'
import AuthLayout from '../../../../../layout/AuthLayout'
import { Box, Text, Flex } from '@mantine/core'
import classes from '../../../AdminLayout.module.css'
import { getListMonitoringScheduleCurriculum, getDetailMonitoringSchedule } from '../../../../../services/monitoring'
import TableSkeleton from '../../../../../components/ui/TableSkeleton'
import CurriculumScheduleList from '../../../../../components/pages/Monitoring/CurriculumScheduleList'
import { updateRoute } from '../../../../../store/dataRoute'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const labelTable = [
  {
    label: 'No.',
    width: 30
  },
  {
    label: 'Nama Materi',
    width: 'auto'
  },
  {
    label: 'Tipe Materi',
    width: 'auto'
  },
  {
    label: 'Status Materi',
    width: 200
  }
]

const CurriculumSchedule = () => {
  const dispatch = useDispatch()
  const parameter = useParams()
  const scheduleId = parameter.id
  const [curriculumList, setCurriculumList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [lockStatus, setLockStatus] = useState(false)

  const mappingRoute = [
    {
      label: '/',
      route: null
    },
    {
      label: 'Jadwal Pelatihan',
      route: '/monitoring'
    },
    {
      label: 'Materi Pelatihan',
      route: null
    },
  ]

  const handleGetMonitoringScheduleCurriculum = async () => {
    setLoading(true)
    try {
      const responseDetail = await getDetailMonitoringSchedule(scheduleId)
      const response = await getListMonitoringScheduleCurriculum(scheduleId)
      setCurriculumList(response.data)
      setCount(response.count)
      setLockStatus(responseDetail.event.isScheduled)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    dispatch(updateRoute({ 'data': mappingRoute }))
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    handleGetMonitoringScheduleCurriculum()
    // eslint-disable-next-line
  }, [])


  return (
    <AuthLayout>
      <Box>
        <Text className={classes.titleLayout} mb={4}>Manajemen Materi Pelatihan</Text>
        <Text fz={12} mb={20}>Total Materi: <Text fz={12} component='span' fw='bold'>{count} Materi</Text></Text>
        <Box>
          <Flex direction={{ base: 'column', sm: 'row' }} gap={{ base: 'sm', sm: 'lg' }} justify={{ base: 'center', lg: 'start' }}>
          </Flex>
        </Box>
        <Box my={20}>
          {loading ? <TableSkeleton total={6} /> : <CurriculumScheduleList label={labelTable} data={curriculumList} lockStatus={lockStatus} handleReload={handleGetMonitoringScheduleCurriculum} />}
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default CurriculumSchedule