const MENU_PERMISSION = {
  dashboard: 'dashboard',
  event: 'event',
  eventCategory: 'event-category',
  curriculum: 'curriculum',
  module: 'module',
  recording: 'recording',
  schedule: 'schedule',
  task: 'task',
  office: 'office',
  position: 'position',
  user: 'user',
  setting: 'setting',
  monitoring: 'monitoring'
}

const MENU_PERMISSION_MEMBER = {
  dashboard: 'dashboard',
  event: 'event',
  setting: 'setting'
}

export { MENU_PERMISSION, MENU_PERMISSION_MEMBER }