import React, { useState, useEffect } from 'react'
import { getDetailCurriculumEventSchedule } from '../../../../services/member-event-schedule'
import { addCurriculumProgress } from '../../../../services/member-curriculum'
import { useParams } from 'react-router-dom'
import { Box, Text, Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import LoadingData from '../../../ui/LoadingData'
import { useViewportSize } from '@mantine/hooks'
import classes from '../../../../pages/member/MemberLayout.module.css'

const UrlEvent = ({ detail }) => {
  const { height } = useViewportSize()
  const params = useParams()
  const scheduleId = params.schedule_id
  const [detailCurriculum, setDetailCurriculum] = useState(null)
  const [loading, setLoading] = useState(true)

  const handleGetDetailCurriculumSchedule = async(curriculumId) => {
    setLoading(true)
    try {
      const response = await getDetailCurriculumEventSchedule(scheduleId, curriculumId)
      setDetailCurriculum(response)
      if (response.curriculumProgresses.length === 0) {
        handleAddProgress(curriculumId)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleAddProgress = async (curriculumId) => {
    const payload = {
      status: 'done',
      userScheduleId: scheduleId,
      curriculumScheduleId: curriculumId
    }
    try {
      await addCurriculumProgress(payload)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (detail !== null) {
      handleGetDetailCurriculumSchedule(detail.id)
    }
  //eslint-disable-next-line
  }, [detail])

  const loadPage = (detail) => {
    return (
      <Box pos='relative'>
        <Text className={classes.titleLayout} mb={6}>{detail.curriculum.name}</Text>
        <Box mt={10} mb={30}>
          <Text fz={12}>{detail.curriculum.description ? detail.curriculum.description : '-'}</Text>
        </Box>
        <Box>
          <Button size='xs' leftSection={<IconDownload size="18px" stroke={1.5} />} color='green' onClick={() => window.open(`${detail.curriculum.content}`, '_blank')}>Buka Materi</Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box mih={height / 1.25}>
      {loading ? <LoadingData /> : loadPage(detailCurriculum)}
    </Box>
  )
}

export default UrlEvent